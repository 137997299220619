import React, { useState , useEffect, createRef } from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Header from '../components/Header'
import Hero from '../components/Hero'
import Loading from '../components/Loading'
import Image from 'gatsby-image'
import { colorArray } from '../assets/lib/constants'

const MembersPageContent = ({ data }) => {
  const members = data.allMemberDataJson.nodes

  const imageRefs = []

  const [loadedCount, setLoadedCount] = useState(0)
  const [imageCompleted, setImageCompleted] = useState(false)

  const onLoadImage = () => {
    setLoadedCount(loadedCount + 1)
  }

  useEffect(() => {
    const completedImages = imageRefs.filter((ref) => 
      ref.current.complete === true
    )
    if (completedImages.length === members.length) {
      setImageCompleted(true)
    }

  }, [members.length, loadedCount, imageRefs])

  const memberListContainerClassName = imageCompleted  ? 'member-list__container member-list__container--show' : 'member-list__container' 
  
  return (
    <div className="main">
    <section className="member-list">
      <div className="member-list__inner">
        {!imageCompleted && (
          <Loading />
        )}
        <ul className={memberListContainerClassName}>
          {members.map((member, index) => {
            const memberFrameClass = `member__image-frame member__image-frame--${colorArray[index % 4]}`

            const newRef = createRef()
            imageRefs.push(newRef)

            return (
              <li className="member-list__item" key={index}>
                <Link className="member-list__link" to={`/member/${ member.path}`}>
                  <div className={memberFrameClass}>
                    <svg className="member__right-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 59">
                      <polygon points="227.846 79.778 246.519 109.154 246.4 109.229 246.521 109.309 227.835 137.693 227 137.143 245.379 109.225 227.002 80.314" transform="translate(-227 -79)"/>
                    </svg>
                    <div className="member__image-container">
                      <img
                        ref={newRef}
                        className="member__image"
                        onLoad={onLoadImage}
                        src={member.image.childImageSharp.fluid.src}
                        alt="member"
                      />
                    </div>         
                  </div>
                  <div className="member-list__name">{ member.name }</div>
                  <div className="member-list__title">{ member.title }</div>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  </div>
  )
}

const MembersPage = (props) => (
  <Layout
    title="メンバー | 株式会社もばらぶ"
    description="株式会社もばらぶは、外房は千葉県茂原市、九十九里の海沿いにある、遠隔勤務など新しい働き方を推進している、IT企業です。まずはITで地方活性化をサポートします。"
    pathName={props.location.pathname}
  >
    <Hero
      title="MEMBERS"
      titleJa="メンバー"
      body="もばらぶのメンバーたちを紹介します。"
    />
    <Header />
    <MembersPageContent data={props.data} />
  </Layout>
)

export const query = graphql`
  {
    allMemberDataJson {
      nodes {
        name
        title
        path
        image {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`

export default MembersPage
