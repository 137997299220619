import React, { useEffect, useState } from 'react'
import lottie from 'lottie-web'
import animationData from '../assets/image/common/loading.json'

const Loading = () => {

  const [showLoading, setShowLoading] = useState(false)

  const loadingClassName = showLoading ? 'loading loading--show' : 'loading'

  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector('#js-loading-icon'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData
    })
    const timer = setTimeout(() => setShowLoading(true), 1000 )

    return function cleanup() {
      clearTimeout(timer)
    }
  }, [])

  return (
    <div id="js-loading-icon" className={loadingClassName}></div>
  )
}

export default Loading
